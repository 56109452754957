<template>
  <div id="Puzzle">
    <div id="ui">
      <p v-if="!displayPuzzle" id="welcomeTitle">
        Welcome to puzzlium ,please Solve this puzzle
      </p>
      <div v-else>
        <iframe v-if="puzzle" :src="puzzle" :width='sizes[0]' :height='sizes[1]' frameborder='0'></iframe>
        <p v-else id="error">
          Incorrect puzzle
        </p>
      </div>
    </div>
    <Scene3d />
  </div>
</template>

<script>
import Scene3d from './Scene3d.vue'

export default {
  name: "Puzzle",
  components: { Scene3d },
  data(){
    return {
      displayPuzzle: false,
      puzzle: null,
      puzzles: {
        "%1{pzzle": "https://puzzel.org/fr/jigsaw/embed?p=-MwiBlWdAhPb6HYvtqFD",
        "78%Pl": "https://puzzel.org/fr/slidingpuzzle/embed?p=-MxWjsueT1W3FkkF7WE5",
        "Puzzliumbetafinal1107": "https://puzzel.org/fr/scavenger-hunt/embed?p=-MxWm-Ehk1m5031AZ4cJ"
      },
      sizes: [0, 0]
    }
  },
  mounted(){
    let url = new URL(window.location.href)
    this.puzzle = this.puzzles[url.searchParams.get('_')]

    setTimeout(() => {
      document.getElementById('welcomeTitle').style.opacity = 0

      setTimeout(() => {
        this.displayPuzzle = true
      }, 1000)
    }, 3000)

    // Resize
    const local = this
    function resizeScreen(){
      local.sizes = [window.innerWidth, window.innerHeight]
    }
    window.addEventListener('resize', () => {
      resizeScreen()
    })
    resizeScreen()
  }
}
</script>

<style scoped>
#ui
{
  position: absolute;
  top: 0;
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
}
/* iframe
{
  height: 100%;
  width: 100%;
} */
#error,
#welcomeTitle
{  
  color: rgb(121, 158, 158);
  text-shadow: 0 0 10px white;
}

#welcomeTitle
{
  transition-duration: 1000ms;
}

@media (orientation: landscape) {
  #welcomeTitle{
    font-size: 4vh;
  }
}
@media (orientation: portrait) {
  #welcomeTitle{
    font-size: 3vw;
  }
}
</style>