<template>
  <Puzzle ref="scene3D" @load="load"/>
</template>

<script>
import Puzzle from './components/Puzzle.vue'

export default {
  name: 'App',
  components: { Puzzle },
}
</script>