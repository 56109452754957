<template>
  <canvas id="canvas3D"/>
</template>

<script>
import * as THREE from 'three'
import Particles from './Particles.js'

export default {
  name: 'Scene3d',
  mounted(){
    // ======== // 
    // Renderer //
    // ======== //         
    const canvas = document.getElementById('canvas3D')
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      alpha: true
    })
    renderer.setClearColor( 0x000000, 1 )

    // ===== // 
    // Scene //
    // ===== //      
    const scene = new THREE.Scene()

    // ====== // 
    // Camera //
    // ====== //
    const camera = new THREE.PerspectiveCamera(75, 0, 0.1, 1000)
    camera.position.set(0, 0, 100)
    scene.add(camera)

    // ========= // 
    // Particles //
    // ========= //
    const particles = new Particles(scene)

    // ==== // 
    // Loop //
    // ==== //
    const clock = new THREE.Clock()
    const tick = () =>
    {
      // Render
      renderer.render(scene, camera) 

      // Recall
      window.requestAnimationFrame(tick)

      // Update Particles
      particles.render(clock.getElapsedTime())
    }
    tick()

    // ============= // 
    // Window Resize //
    // ============= //
    function updateSizes(){      
      // Store new sizes
      const sizes = {
        height: window.innerHeight,
        width: window.innerWidth
      }

      // Update camera
      camera.aspect = sizes.width / sizes.height
      camera.updateProjectionMatrix()

      // Update renderer
      renderer.setSize(sizes.width, sizes.height)
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    }

    window.addEventListener('resize', () => {
      updateSizes()
    })

    updateSizes()
  }
}
</script>